import { I18N } from 'aurelia-i18n';
import { ValidationController, ValidationRules, Validator } from 'aurelia-validation';
import { ServiceCheckpointService } from './../../services/service-checkpoint-service';
import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';
import { DialogController, DialogService } from 'aurelia-dialog';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { PLATFORM, bindable } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import * as moment from 'moment';
import { getMonthsForServiceExtension } from 'lib/serivce/serviceHelpers';

@autoinject
export class ServiceDetailExtendValidity {
  private net: Models.Net;
  private netdimension: Models.NetDimension;
  private specialProductDimension: Models.SpecialProductDimension;
  private service: Models.Service;
  private radio;
  private locked;
  private approvedExtendedPeriod;
  private declinedExtendedPeriodApproval;
  private nextTabIndex: number = null;

  private checkpoints: Array<any>;

  private canApprove: boolean = false;
  private checkpointsOk: boolean = false;

  private approveForMonths = 3;

  private subscription: Subscription;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;

  constructor(
    private errorService: ErrorService,
    private netService: NetService,
    private toastService: ToastService,
    private serviceService: ServiceService,
    private i18n: I18N,
    private netDimensionService: NetDimensionService,
    private router: Router,
    private dialogService: DialogService,
    private eventAggregator: EventAggregator,
    private validator: Validator,
    private validationController: ValidationController,
    private serviceCheckpointService: ServiceCheckpointService,
    private specialProductDimensionService: SpecialProductDimensionService
  ) { }

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });

    this.subscription = this.eventAggregator.subscribe('service-unlocked', (serviceId) => {
      if (this.service.Id === serviceId) {
        this.getService(this.service.Id);
      }
    });
  }

  deactivate() {
    if (this.subscription) {
      this.subscription.dispose();
    }
  }

  private saveService() {
    this.serviceService
      .put(this.service, this.service.Id)
      .then((res) => {
        this.toastService.showSuccess('service.washSaved');
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private async checkCanApproveExtendedPeriod(id: number) {
    try {
      const validity = await this.serviceService.checkCanApproveExtendedPeriod(id)
      this.checkpointsOk = validity.CheckpointsOk;
      this.canApprove = validity.CanApprove;
    } catch (error) {
      this.errorService.handleError(error)
    }
  }

  private async getService(id) {
    try {
      await this.checkCanApproveExtendedPeriod(id);

      const service = await this.serviceService.get(id + '?$expand=NetStandard');
      this.service = service;

      this.setValidationRules();

      const checkpoints = await this.serviceCheckpointService.getCheckpoints(id);
      this.checkpoints = checkpoints;

      this.approveForMonths = getMonthsForServiceExtension(service);

      this.locked = service.Locked ? service.Locked : false;
      this.approvedExtendedPeriod = service.ApprovedExtendedPeriod;
      this.declinedExtendedPeriodApproval = service.DeclinedExtendedPeriodApproval;

    } catch (error) {
      this.errorService.handleError(error)
    }
  }

  private setValidationRules() {
    ValidationRules
      .ensure('DeliveredDate').required().withMessage(this.i18n.tr('general.requiredField'))
      .on(this.service);
  }

  private validateBeforeApprove() {
    // MANUAL VALIDATION
    this.validator.validateObject(this.service)
      .then((result) => {
        const errors = result.filter((validateResult) => {
          return !validateResult.valid;
        });
        if (errors.length > 0) {
          this.validationController.validate();
        } else {
          this.approveAndFinish();
        }
      });
  }

  private getNet(id) {
    this.netService
      .getNetInfo(id)
      .then((res) => {
        this.net = res;
        this.getDimensions(res);
      })
      .catch((err) => this.errorService.handleError(err));
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private saveCommentExtendedPeriod() {
    this.serviceService.patchValue(this.serviceId, 'CommentExtendedPeriod', this.service.CommentExtendedPeriod)
      .then(res => {
        this.toastService.showSuccess('service.CommentExtendedPeriodUpdated');
      });
  }

  private approveAndFinish() {
    if (!this.canApprove) {
      this.dialogService.open({
        viewModel: Prompt,
        model: {
          header: 'dialog.serviceApproveAndExtendHeader',
          message: 'dialog.serviceCannotApproveExtendedPeriod',
          actions: {
            delete: { enabled: false },
            save: { enabled: false },
            cancel: { enabled: false },
            dontsave: { enabled: false },
            continue: { enabled: true, t: 'dialog.ok' }
          }
        }
      });

      return;
    }

    return this.dialogService.open({
      viewModel: Prompt,
      model: {
        header: 'dialog.serviceApproveAndExtendHeader',
        message: this.i18n.tr('dialog.serviceApproveAndExtendText', {months: this.approveForMonths}),
        actions: {
          delete: { enabled: false },
          save: { enabled: false },
          cancel: { enabled: true, t: 'dialog.cancel' },
          dontsave: { enabled: false },
          continue: { enabled: true, t: 'dialog.serviceApproveAndExtend' }
        }
      }
    }).whenClosed((response) => {
      if (response.wasCancelled) {
        return false;
      } else {
        const result = response.output;
        if (result === 'continue') {
          this.service.DeliveredDate = <any>moment.default(this.service.DeliveredDate).format('YYYY-MM-DD');
          this.serviceService
            .put(this.service, this.service.Id)
            .then((updateObject) => {
              this.serviceService
                .approveExtendedPeriod(this.service.Id)
                .then((res) => {
                  this.toastService.showSuccess('service.approvedAndExtended');
                  this.eventAggregator.publish('serviceLocked');
                  this.getService(this.service.Id);
                })
                .catch((err) => this.errorService.handleError(err));
            })
            .catch(err => this.errorService.handleError(err));

          return false;
        } else {
          return true;
        }
      }
    });
  }

  private dontApprove() {
    return this.dialogService.open({
      viewModel: Prompt,
      model: {
        header: 'dialog.serviceDontApproveExtendValidityHeader',
        message: 'dialog.serviceDontApproveExtendValidityText',
        actions: {
          delete: { enabled: false },
          save: { enabled: false },
          cancel: { enabled: true, t: 'dialog.cancel' },
          dontsave: { enabled: false },
          continue: { enabled: true, t: 'dialog.serviceDontApproveExtendValidity' }
        }
      }
    }).whenClosed((response) => {
      if (response.wasCancelled) {
        return false;
      } else {
        const result = response.output;
        if (result === 'continue') {

          // request approval
          this.serviceService
            .dontApproveExtendedPeriod(this.service.Id)
            .then((res) => {
              this.toastService.showSuccess('service.extendedValidityNotApproved');
              this.eventAggregator.publish('serviceLocked');
              this.getService(this.service.Id);
            })
            .catch((err) => this.errorService.handleError(err));

          return false;
        } else {
          return true;
        }
      }
    });
  }

  checkpointChanged(item, event) {
    event.stopPropagation();
    delete item.ServiceCheckpointGroup;

    this.serviceCheckpointService.updateCheckpoint(item)
      .then(updatedCheckpoint => {
        // replace the item in the array with the saved item
        this.checkpoints.forEach(g => {
          let index = g.Checkpoints.findIndex(x => x.Id == item.Id)
          if (index > -1) {
            g.Checkpoints.splice(index, 1, updatedCheckpoint);
          }
        });

        this.checkCanApproveExtendedPeriod(this.service.Id);

        this.toastService.showSuccess('service.checkpointUpdated');
      })
      .catch(err => this.errorService.handleError(err));
  }

  public toggleCheckpointItem(item, event) {
    event.stopPropagation();

    setTimeout(() => {
      this.checkpointChanged(item, event);
    }, 100);

    return true;
  }

  public canDeactivate() {
    this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
    return true;
  }
}
