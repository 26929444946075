import * as signalR from '@microsoft/signalr';
import { DialogService, DialogController } from 'aurelia-dialog';
import { bindable, LogManager } from 'aurelia-framework';
import { autoinject } from 'aurelia-framework';
import { Logger } from 'aurelia-logging';
import { Router } from 'aurelia-router';
import { Models } from 'models/core';
import * as moment from 'moment';
import { AccountService } from 'services/account-service';
import { ErrorService } from 'services/error-service';
import { FileAttachmentService } from 'services/file-attachment-service';
import { FileService } from 'services/file-service';
import { NetService } from 'services/net-service';
import { ServiceRepairService } from 'services/service-repair-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { ServiceCounterCommentDialog } from './service-counter-comment-dialog';

const logger: Logger = LogManager.getLogger("service-counter");

@autoinject
export class ServiceCounter {

  private rows: Array<any>;
  private connection;

  private service: Models.Service;
  private net: Models.Net;
  private serviceRepairs: Array<Models.ServiceRepair>;
  private serviceRepairTypes: Array<Models.ServiceRepairType>;

  private fields: Array<any>;
  private dialogOpen: number;
  private selectedFiles: Array<any>;
  private previousAction: any;
  private previousActionText: any;
  private previousActionTimer: any;

  private connected: boolean = false;

  constructor(
    private dialogService: DialogService,
    private toastService: ToastService,
    private accountService: AccountService,
    private router: Router,
    private serviceService: ServiceService,
    private netService: NetService,
    private errorService: ErrorService,
    private serviceRepairService: ServiceRepairService,
    private fileService: FileService,
    private fileAttachmentService: FileAttachmentService,
    private dialogController: DialogController
  ) {}

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);
    this.getServiceRepairs(params.Id);
  }

  private attached() {

    this.fields = [
      'Repairs1To3Msk',
      'Repairs4To10Msk',
      'RepairsOver10Msk',
      'LooseRopes',
      'BrokenLaces'
    ];

    moment.defineLocale('no-nb', {
      parentLocale: 'en',
      relativeTime : {
          future: 'om %s',
          past:   '%s siden',
          s  : 'noen sekunder',
          ss : '%d sekunder',
          m:  'et minutt',
          mm: '%d minutter',
          h:  'en time',
          hh: '%d timer',
          d:  'en dag',
          dd: '%d dager',
          M:  'en måned',
          MM: '%d måneder',
          y:  'et år',
          yy: '%d år'
      }
    });

    moment.locale('no-nb');

    this.connection = new signalR.HubConnectionBuilder()
    .withUrl('/signalr/servicerepair', { accessTokenFactory: () => this.accountService.getAuthToken() })
    .withAutomaticReconnect()
    .build();

    // Start connection
    this.connection.start()
    .then(() => {
        logger.debug('Connection active!');
        this.connected = true;
    })
    .catch((err) => {
      logger.debug('Connection failed:', err);
      this.toastService.showError('counter.connectionFailed');
      this.connected = false;
    });

    this.connection.onreconnecting((error) => {
      logger.debug('Reconnecting: ', error);
      this.toastService.showLoading('counter.reconnecting');
      this.connected = false;
    });

    this.connection.onreconnected((connectionId) => {
      logger.debug('Reconnected!', connectionId);
      this.toastService.clear();
      this.toastService.showSuccess('counter.reconnected');
      this.connected = true;
    });

    this.connection.onclose((error) => {
      if (this.connection.state === signalR.HubConnectionState.Disconnected) {
        if (!this.connected) {
          this.toastService.clear();
          this.toastService.showError('counter.permanentlyDisconnected');
        } else {
          this.connected = false;
        }
      }
    })

    // Listener for increments
    this.connection.on('increment', (serviceId, serviceTypeId, field, newCount) => {
      if (serviceId !== this.service.Id) {
        return;
      }
      this.serviceRepairs.filter((it) => it.ServiceRepairTypeId === serviceTypeId)[0][field] = newCount;
    });

    // Listener for decrements
    this.connection.on('decrement', (serviceId, serviceTypeId, field, newCount) => {
      if (serviceId !== this.service.Id) {
        return;
      }
      this.serviceRepairs.filter((it) => it.ServiceRepairTypeId === serviceTypeId)[0][field] = newCount;
    });

    // Listener for new comments
    this.connection.on('newcomment', (serviceId, serviceRepairId, commentNew) => {
      if (serviceId !== this.service.Id) {
        return;
      }

      this.serviceRepairs.filter((it) => it.Id === serviceRepairId)[0].Other = commentNew;
      if (this.dialogOpen === serviceRepairId) {
        // setTimeout to avoid UI-bug (refresh-button shows for a millisecond when saving as noraml)
        setTimeout(() => {
          const it: any = this.serviceRepairs.filter((it) => it.Id === serviceRepairId)[0];
          it._gotUpdated = true;
        }, 0.5 * 1000);
      }

    });

  }

  private getService(id) {
    this.serviceService
    .get(id)
    .then((res) => {
      this.service = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
    .get(id)
    .then((res) => {
      this.net = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getServiceRepairs(id) {
    this.serviceRepairService
    .getAll('?$filter=ServiceId eq ' + id + '&$expand=ServiceRepairType')
    .then((res) => {
      this.serviceRepairs = res;
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private editComment(serviceRepair) {
    this.dialogOpen = serviceRepair.Id;
    serviceRepair._gotUpdated = false;
    const previousComment = serviceRepair.Other;
    this.dialogService.open({
      viewModel: ServiceCounterCommentDialog,
      model: {
        serviceRepair,
        previousComment
      },
      lock: false
    }).whenClosed((response) => {
      this.dialogOpen = null;
      if (!response.wasCancelled) {
        this.toastService.showSuccess('counter.commentUpdated');
        this.actionFired();
      }
    });
  }

  private selectImage(event) {

    if (!this.selectedFiles[0]) {
      return;
    }

    this.toastService.showLoading('counter.uploadingImage');
    this.fileService.upload(this.selectedFiles[0], this.net.CustomerId)
      .then((res) => {
        // notify parent component
        this.fileAttachmentService.setApiUrl('api/services');
        this.fileAttachmentService.addFile(this.service.Id, res.Id)
        .then((ress) => {
          this.toastService.clear();
          this.toastService.showSuccess('counter.imageUploaded');
          this.actionFired();
        })
        .catch((err) => this.errorService.handleError(err));
      })
      .catch((err) => this.errorService.handleError(err)
    );

  }

  private goBack() {
    this.router.navigate('service/' + this.service.Id + '/net/' + this.net.Id + '/repair');
  }

  /* Realtime-functions */
  private increment(serviceRepair, field) {
    this.connection.invoke('Increment', this.service.Id, serviceRepair.ServiceRepairTypeId, field);
    this.actionFired();
  }

  private decrement(serviceRepair, field) {
    this.connection.invoke('Decrement', this.service.Id, serviceRepair.ServiceRepairTypeId, field);
    this.actionFired();
  }

  private actionFired() {
    clearInterval(this.previousActionTimer);
    this.previousAction = moment.default();
    this.previousActionText = moment.default(this.previousAction).fromNow();
    this.previousActionTimer = setInterval(() => {
      this.previousActionText = moment.default(this.previousAction).fromNow();
    }, 1 * 1000);
  }

  private closeCounterDialog() {
    this.connection.stop();
    this.dialogController.cancel();
  }

}
