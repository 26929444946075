import { ToastService } from 'services/toast-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { I18N } from 'aurelia-i18n';
import { ErrorService } from 'services/error-service';
import { autoinject, customAttribute } from 'aurelia-framework';
import './send-to-service-station-dialog.scss';
import { DialogController } from 'aurelia-dialog';

@autoinject
export class SendToServiceStationDialog {
    private params;
    private service: any;
    private net: any;
    private takeOutOfSeaDialogText;
    private hasConfirmed: boolean = false;

    constructor(private dialogController: DialogController,
        private serviceService: ServiceService,
        private netService: NetService,
        private toastService: ToastService,
        private errorService: ErrorService,
        private i18n: I18N) {                 
    }

    activate(params) {
        this.service = params.service;
        this.net = params.net;

        this.takeOutOfSeaDialogText = this.i18n.tr('service.sendToServiceStationDialogText').split('\n').join('<br/>');

    }

    updatePlacement() {
        if (!this.hasConfirmed) {
            this.toastService.showError('service.mustConfirmSendToServiceStation')
            return;
        }

        this.netService.sendNetToServiceStationAquacom(this.net.Id, this.service.ServiceStationId)
            .then(res => {
                this.dialogController.close(true);
            })
            .catch(err => this.errorService.handleError(err));
    }
}
