import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { Prompt } from 'elements/prompt';
import { Models } from 'models/core';
import * as moment from 'moment';
import { DisinfectionTypeService } from 'services/disinfection-type-service';
import { ErrorService } from 'services/error-service';
import { NetDimensionService } from 'services/net-dimension-service';
import { NetService } from 'services/net-service';
import { ServiceService } from 'services/service-service';
import { ToastService } from 'services/toast-service';
import { Utility } from '../../utility';
import { SpecialProductDimensionService } from './../../services/special-product-dimension-service';

@autoinject
export class ServiceDetailHours {

  private net: Models.Net;
  private netdimension: Models.NetDimension;
  private service: Models.Service;
  private nextTabIndex: number = null;
  private originalObject;
  private locked: boolean = false;

  private specialProductDimension: Models.SpecialProductDimension;

  @bindable private serviceId;
  @bindable private netId;
  @bindable private isDialog: boolean = false;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private eventAggregator: EventAggregator,
    private netService: NetService,
    private router: Router,
    private toastService: ToastService,
    private utility: Utility,
    private serviceService: ServiceService,
    private netDimensionService: NetDimensionService,
    private specialProductDimensionService: SpecialProductDimensionService,
    private disinfectionTypeService: DisinfectionTypeService
  ) { }

  private attached() {
    if (this.serviceId && this.netId) {
      this.activate({ Id: this.serviceId, NetId: this.netId });
    }
  }

  private activate(params) {
    this.getService(params.Id);
    this.getNet(params.NetId);

    // Get new tabIndex for tabs component via EA, store value so we can publish this if canDeactivate returns true
    this.eventAggregator.subscribe('changeTab', (tabIndex) => {
      this.nextTabIndex = tabIndex;
    });
  }

  private saveService() {
    this.serviceService
    .put(this.service, this.service.Id)
    .then((res) => {
      this.toastService.showSuccess('service.washSaved');
      this.eventAggregator.publish('serviceUpdated');
      this.updateOriginalObject();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private saveServiceAndUpdateStatus(status) {
    this.serviceService.updateServiceStatus(status, this.service.Id)
    .then(res => {
      if (!this.service.WashedDate) {        
        this.service.WashedDate = res.WashedDate;
      }
      if (!this.service.MeasuredDate) {
        this.service.MeasuredDate = res.MeasuredDate;
      }
      this.toastService.showSuccess('service.serviceStatusChanged');
      this.saveService();
    })
    .catch(err => this.errorService.handleError(err));
  }

  private getService(id) {
    this.serviceService
    .get(id)
    .then((res) => {
      this.service = res;
      this.locked = res.Locked ? res.Locked : false;
      this.updateOriginalObject();
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getNet(id) {
    this.netService
    .getNetInfo(id)
    .then((res) => {
      this.net = res;      
      this.getDimensions(res);      
    })
    .catch((err) => this.errorService.handleError(err));
  }

  private getDimensions(net: Models.Net) {
    if (net.NetDimensionId) {
      this.netDimensionService
        .get(net.NetDimensionId + '?$expand=DimensionClass')
        .then((res) => {
          this.netdimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    } else if (net.SpecialProductDimensionId) {
      this.specialProductDimensionService
        .get(net.SpecialProductDimensionId)
        .then((res) => {
          this.specialProductDimension = res;
        })
        .catch((err) => this.errorService.handleError(err));
    }
  }

  private setWashAndMeasurementsFileId(event: any) {
    this.service.WashAndMeasurementsFileId = event.detail.id;
  }

  private removeWashAndMeasurementsFileId(event: any) {
    this.service.WashAndMeasurementsFileId = null;
  }

  public canDeactivate() {

    if (!this.originalObject) {
      return true;
    }

    this.originalObject.WashedDate = moment.default(this.originalObject.WashedDate).format('YYYY-MM-DD');
    this.service.WashedDate = moment.default(this.service.WashedDate).format('YYYY-MM-DD') as any;
    
    if (!this.utility.areEqual(this.service, this.originalObject)) {
      return this.dialogService.open({
        viewModel: Prompt,
        model: { header: 'dialog.pleaseConfirmHeader', message: 'dialog.unsavedChangesText' }
      }).whenClosed((response) => {
        if (response.wasCancelled) {
          return false;
        } else {
          const result = response.output;
          if (result === 'save') {
            // Save the service and let that function handle the rest of the logic
            this.saveService();
            return false;
          } else {
            this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
            return true;
          }
        }
      });
    } else {
      this.eventAggregator.publish('changeTab-success', this.nextTabIndex);
      return true;
    }
  }

  private updateOriginalObject() {
    this.originalObject = JSON.parse(JSON.stringify(this.service));
  }

}
