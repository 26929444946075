import {DialogController} from 'aurelia-dialog';
import { autoinject } from 'aurelia-framework';
import { ErrorService } from 'services/error-service';
import { ToastService } from 'services/toast-service';
import { ServiceRepairService } from '../../../services/service-repair-service';

@autoinject
export class ServiceCounterCommentDialog {

  public serviceRepair: any;

  private comment: string;
  private previousComment: string;

  constructor(
    private controller: DialogController,
    private serviceRepairService: ServiceRepairService,
    private toastService: ToastService,
    private errorService: ErrorService
  ) {
    //
  }

  public activate(data) {
    this.serviceRepair = data.serviceRepair;
    this.previousComment = data.previousComment;
    this.comment = data.serviceRepair.Other;
  }

  private saveComment() {
    this.serviceRepairService
    .updateComment(this.serviceRepair.Id, this.previousComment, this.comment)
    .then((res: any) => {

      if (res.ok) {
        this.previousComment = this.comment;
        this.serviceRepair._gotUpdated = false;
        this.controller.ok();
      } else {
        this.serviceRepair._gotUpdated = true;
      }

    })
    .catch((err) => this.errorService.handleError(err));
  }

  private refreshComment() {
    this.comment = this.serviceRepair.Other;
    this.previousComment = this.serviceRepair.Other;
    this.serviceRepair._gotUpdated = false;
  }

}
